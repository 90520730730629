<template>
  <base-section
    id="about-me"
    class="grey lighten-4 pt-18"
  >
    <v-container>
      <v-row
        justify="space-around"
        class="mb-4"
      >
        <v-avatar
          size="230"
          class="elevation-4"
        >
          <v-img
            src="@/assets/Cassiano7.jpg"
          />
        </v-avatar>
      </v-row>
    </v-container>

    <base-section-heading
      class="black--text"
      title="Quem é Cassiano Diniz"
    >
      <h3>Criador do método Nutrição Moderna:</h3>
    </base-section-heading>

    <v-container class="pa-4 pt-0">
      <v-row class="ma-4">
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            <span v-html="feature.description" />
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <base-btn-plans />
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutMe',

    data: () => ({
      features: [
        {
          title: 'Graduado em Nutrição CRN 10 8212',
          icon: 'mdi-school',
          description: 'Pós-graduado em Comportamento Alimentar. Estuda com foco em comportamento alimentar, terapia nutricional nas Doenças crônicas não-transmissíveis, hormônios no esporte e nutrição para estética, esporte e saúde.',
        },
        {
          title: 'Autor',
          icon: 'mdi-book-open-page-variant',
          description: 'Autor dos tópicos de sono, comportamento e transtornos alimentares do livro internacional <i>Ghrelin: funcion, mechanism of action and role in health and disease</i> (traduzido Grelina: função, mecanismo de ação e papel na saúde e na doença)',
        },
        {
          title: 'Colaborador',
          icon: 'mdi-hospital-building',
          description: 'Colaborador na elaboração dos Procedimentos Operacionais de Padronização de Nutrição do maior hospital de Santa Catarina.',
        },
        {
          title: 'Professor',
          icon: 'mdi-account-tie-voice-outline',
          description: 'Professor de Pós-Graduação em Nutrição e Comportamento Alimentar na UNIFAGOC.',
        },
        {
          title: 'Experiência',
          icon: 'mdi-hubspot',
          description: 'Há 8 anos atua como consultor pessoal na área de saúde e esporte e nutricionista já tendo ajudado milhares de pessoas em todo o Brasil, bem como na Alemanha, Canadá, Suíça, México, Estados Unidos, Portugal e Polônia.',
        },
        {
          title: 'Diversidade',
          icon: 'mdi-shape',
          description: 'Experiência no atendimento de atletas de fisiculturismo, Cross Fit, powerlifting, ciclismo, artes marciais (diversas), corrida, entre outras modalidades. Pessoas de todas as idades, portadores de doença ou restrições alimentares.',
        },
      ],
    }),

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
